@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

.headerBg {
  background-color: white;
  padding: 1em;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: solid;
  border-bottom-color: black;
}

.logo {
  // width: 160px;
  // height: 50px;
  width: 225px;
  // height: 64px;
}

.btnDiv {
  width: 15%;
  display: inline-flex;
  justify-content: space-between;
  padding-right: 3em;
  align-items: center;
}

.btn {
  cursor: pointer;
}

.btnSelected {
  color: orange;
  text-decoration: underline;
}

.dashboardMain {
  width: 100%;
  display: flex;
  margin: 2rem 0 3rem;
  .headerM {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navmenu {
      display: flex;
      align-items: center;
      .menuItem {
        margin-right: 40px;
        color: #8b9297;
        cursor: pointer;
        #dropdown-basic {
          padding: 0;
          color: #8b9297;
          &.dropdown-toggle::after {
            margin-left: 10px;
          }
        }
        .dropMenug {
          border: 1px solid #6b96f6;
          .clickItem {
            background-color: #6b96f612;
            font-size: 14px;
            &:active,
            &:hover {
              background-color: #38c798;
              color: #fff;
            }
          }
        }
        &.show {
          color: #1d2935;
        }
        &.active,
        #dropdown-basic.active {
          color: #49535c;
          img {
            margin-right: 5px;
            margin-top: -2px;
          }
        }
      }
    }
    .cartLogo {
      width: 150px;
      cursor: pointer;
      margin-right: 70px;
    }

    .logoutBtn {
      background: #f8c729;
      box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
      border-radius: 14px;
      color: #000;
      font-weight: 800;
      padding: 6px 20px;
      border: unset;
      margin-right: 20px;
    }

    .dropdown {
      position: relative;
      display: inline-block;

      span {
        font-weight: 700;
        margin-right: 10px;
      }
    }

    .dropdown-content {
      margin-left: 15px;
      cursor: pointer;

      .tooltip-inner {
        display: inline-block;
        position: relative;
        color: #333;
        background-color: #fff;

        margin: 0 0 10px 0;
        filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
        &.tooltip-inner::after {
          right: unset;
          border-color: unset;
          border-width: unset;
        }
        &.tooltip-inner::before {
          right: unset;
          border-color: unset;
          border-width: unset;
        }
      }
      .tooltip-arrow {
        display: none !important;
      }
      img {
        width: 24px;
      }
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    .profile {
      display: flex;
      align-items: center;

      .profileImg {
        // width: 60px;
        // height: 60px;
        // border-radius: 50%;
        // border: 1px solid #38c798;
        margin-right: 10px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        img {
          width: 56px;
        }
      }
    }
  }
}

// new scss
.sidebarNav {
  .MuiBox-root {
    .MuiAppBar-root {
      margin-left: 0;
      width: 100%;
      left: 0;
      background-color: $white;
      z-index: 1201;
      box-shadow: unset;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: $border-color;

      .MuiToolbar-root {
        justify-content: space-between;
        padding: 0px 24px 0px 14px;
        .logoDiv {
          .resaleLogo {
            width: 100%;
            max-width: 120px;
          }
        }
        .profileContainer {
          .profileImg {
            margin-right: 15px;
          }
          .userName {
            p {
              color: $text-grey;
              font-size: $font-14;
              &:last-child {
                font-size: $font-10;
              }
            }
          }
        }
      }
    }
    .MuiDrawer-root {
      .MuiDrawer-paper {
        overflow-y: unset;
        height: calc(100vh - 50px);
        margin-top: 50px;
        .MuiList-root {
          padding-top: 0;
          padding-bottom: 0;
          height: 100%;
          position: relative;
          .MuiListItem-root {
            position: relative;
            .MuiListItemButton-root {
              justify-content: center;
              padding: 14px 0px;
              .MuiListItemIcon-root {
                display: unset;
                min-width: unset;

                .menuIcon {
                  width: 100%;
                  max-width: 24px;
                }
              }
              &.Mui-selected {
                background-color: $theme-color;
                .MuiListItemIcon-root {
                  .whiteOnHoverFill {
                    fill: $white;
                  }
                  .whiteOnHoverStroke {
                    stroke: $white;
                  }
                }
              }
            }

            .mainDiv {
              .hoverMenuContainer {
                background-color: transparent;
                position: absolute;
                top: 0px;
                left: -220px;
                width: 220px;
                display: flex;
                .arrow-left {
                  width: 0;
                  height: 0;
                  border-top: 10px solid transparent;
                  border-bottom: 10px solid transparent;
                  border-right: 12px solid $theme-color;
                  position: relative;
                  left: 4px;
                  top: 13px;
                  z-index: 1;
                }
                .hoverSubmenu {
                  position: relative;
                  width: 200px;
                  background: $white;
                  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%);
                  border-radius: 6px;
                  overflow: hidden;
                  .MuiList-root {
                    padding: 0;
                    .MuiListItem-root {
                      position: relative;
                      .MuiListItemButton-root {
                        padding: 12px 0px 12px 20px;
                        color: $text-grey;
                        background-color: $white;
                        .MuiListItemText-root {
                          margin: 0;
                          .MuiTypography-root {
                            // font-family: "lightFont";
                            font-weight: $font-5w;
                            font-size: $font-14;
                          }
                        }
                        &.Mui-selected {
                          background-color: $light-theme-color;
                          color: $theme-color;
                        }
                      }
                      &.MainMenu {
                        .MuiListItemButton-root {
                          background: $theme-color;
                          color: $white;
                          cursor: default;
                          .MuiListItemText-root {
                            .MuiTypography-root {
                              font-weight: $font-6w;
                              font-size: $font-16;
                            }
                          }
                        }
                      }
                      &:hover {
                        .MuiListItemButton-root:not(.MainMenu .MuiListItemButton-root) {
                          background-color: $light-theme-color;
                          color: $theme-color;
                        }
                      }
                    }
                  }
                }
              }
            }

            &:hover {
              .hoverMenuContainer {
                left: 60px;
              }
              .MuiListItemButton-root {
                background-color: $theme-color;
                .MuiListItemIcon-root {
                  .whiteOnHoverFill {
                    fill: $white;
                  }
                  .whiteOnHoverStroke {
                    stroke: $white;
                  }
                }
              }
            }
            &:last-child {
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
  }
  // .logoutBtn {
  //   margin-top: auto;
  //   text-align: center;
  //   margin-bottom: 15px;
  //   img {
  //     width: 100%;
  //     max-width: 28px;
  //   }
  // }
}

@media (min-width: 600px) {
  .sidebarNav {
    .MuiBox-root {
      .MuiAppBar-root {
        .MuiToolbar-root {
          min-height: 50px;
        }
      }
    }
  }
}
