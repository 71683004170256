.productContainerUPC {
    background: white;
    padding: 32px;
    margin-top: 20px;
    .imgSection {
        display: flex;
        align-items: center;
        // // margin-right: 10px;
        flex-direction: row;
        align-items: flex-start;
        img {
            border-radius: 2px;
            border: 1px solid #dfdbdb;
            // margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        
    }
    // .productDetails{
    //     margin-left: 20px;
    // }
}