@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

.warhouseStart {
    .badge {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        margin: 0px 15px;
        background-color: $theme-color;
    }
    .FilledBtn {
        max-width: 190px;
    }
    .updateMsg {
        color: #717171;
        font-size: 12px;
    }
    .updateMsgHide {
        visibility: hidden;
    }
}

.warhouseStart {
    .adminDashboard {
        display: flex;
        .warehouseL {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 30px;
            .headingD {
                margin-right: 30px;
                // h5 {
                //     color: #1d2935;
                //     font-weight: 500;
                //     margin-bottom: 10px;
                // }
            }
        }
        .warhouseS {
            height: calc(100vh - 190px);
            overflow-y: auto;
            .warehousedetail {
                padding: 10px;
                margin-bottom: 15px;
                background-color: $white;
                box-shadow: 0 1px 7px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
                border-left: 1px solid $theme-color;
                border-radius: 2px;
                transition: 0.2s;
                cursor: pointer;
                &.userDetails {
                    // padding-bottom: 20px;
                    .user-info {
                        margin-left: 20px;
                    }
                }
                .firstN {
                    font-size: 16px;
                    color: #1d2935;
                    font-weight: 600;
                    margin-bottom: 0px;
                }
                .user-role {
                    border: 1px solid #c1eedf;
                    border-radius: 24px;
                    padding: 2px 10px;
                    // margin-bottom: 10px;
                    // margin-right: auto;
                }
                .user-email {
                    color: #487baf;
                    text-decoration: underline;
                }
                .warhouseLast {
                    .warehouseMaind {
                        flex-wrap: wrap;
                        .warehouseItem {
                            margin-right: 20px;
                            display: flex;
                            flex-direction: column;
                            .edit-dlt-btns {
                                margin-left: auto;
                                .editicon {
                                    margin-right: 10px;
                                }
                            }
                            &:last-child {
                                margin-right: 0px;
                            }
                        }
                        .warehouseNew {
                            .prefixBtn {
                                width: 100%;
                                background-color: #fafafa;
                                padding: 6px 8px;
                                // margin-right: 10px;
                                border: 1px solid $theme-color;
                                border-radius: 6px;
                                color: $text-grey;
                            }
                            .warehouseNode {
                                .warhouseedits {
                                    margin-bottom: 5px;
                                    visibility: hidden;
                                    button {
                                        background-color: unset;
                                        border: unset;
                                        // .icon {
                                        //     width: 20px;
                                        // }
                                    }
                                }
                                .dashed-input {
                                    background-color: unset;
                                    border: 1px dashed #6b96f6;
                                    padding: 6px 8px;
                                    color: $text-grey;
                                    border-radius: 6px;
                                    // margin-right: 15px;
                                }
                                .updateBtn {
                                    // background-color: unset;
                                    // border: 1px dashed #6B96F6;
                                    // padding: 8px;
                                    // color: #6B96F6;
                                    // border-radius: 5px;
                                    background: #6b96f6;
                                    border: 1px solid #6b96f6;
                                    border-radius: 6px;
                                    color: #fff;
                                    padding: 6px 12px;
                                    margin-left: 10px;
                                }
                                &:hover {
                                    .warhouseedits {
                                        visibility: visible;
                                    }
                                    .prefixBtn {
                                        background-color: $theme-color;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                    .enterBtn {
                        border: 1px solid #6b96f6;
                        background: #6b96f6;
                        border-radius: 5px;
                        color: #fff;
                        font-weight: 500;

                        padding: 6px 12px;
                        width: 100%;
                        max-width: 171px;
                        margin-bottom: 20px;
                    }
                    .addPrefixD {
                        .FilledBtn {
                            justify-content: center;
                            max-width: 154px;
                            padding: 0px 20px;
                        }
                        .deleteIcon {
                            display: none;
                        }
                    }
                }
                &:hover {
                    border-left-width: 6px;
                    .addPrefixD {
                        .deleteIcon {
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                }
                &.userDetails {
                    padding: 20px 10px;
                }
            }
        }
    }
    .addD {
        background: #6b96f6;
        border-radius: 5px;
        color: #fff;
        border: unset;
        padding: 6px 14px;
        margin-top: 10px;
    }
}
