@import "../utilities/variables.scss";

input {
    &:focus-visible {
        outline: unset;
    }
}
//outline btn
.FilledBtn {
    margin-left: auto;
    border-radius: 6px;
    height: 38px;
    // width: 100%;
    // max-width: 100px;
    border: 1px solid $light-blue;
    color: $light-blue;
    background-color: #fff;
    padding: 0px 20px;
    &.disabled-btn {
        background-color: #fff;
    }
    &:hover,
    &:active {
        background-color: $light-blue;
        color: #fff;
    }
}

//react datepicker
@mixin selectContainer {
    padding: 5px;
    border-color: $theme-color;
    border-radius: 6px;
    &:focus-visible {
        outline: unset;
    }
}
.react-datepicker {
    border-color: $theme-color;
    box-shadow: 4px 4px 14px rgb(0 0 0 / 15%);
    .react-datepicker__navigation {
        display: none;
    }
    .react-datepicker__current-month {
        display: none;
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select {
        @include selectContainer;
    }
    .react-datepicker__day--selected {
        @include selectContainer;
        padding: 0;
        background-color: $theme-color;
    }
    .react-datepicker__day--keyboard-selected {
        background-color: $theme-color;
    }
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: $theme-color;
}
// search input
.serchContainer {
    border: 1px solid $light-blue;
    border-radius: 6px;
    height: 38px;
    background: $white;
    padding: 0px 10px;
    align-items: center;
    width: 230px;
    .searchIcon {
        margin-right: 5px;
        width: 100%;
        max-width: 18px;
    }
    input {
        border: unset;
    }
}
//pointer
.pointer {
    cursor: pointer;
    &.link {
        color: $theme-color;
    }
}
// main content container
.MuiBox-root {
    &.contentMain {
        margin-top: 50px;
        background-color: $main-bg-color;
        width: calc(100% - 60px);
        margin-left: 60px;
        height: calc(100vh - 50px);
        overflow-y: auto;
    }
}

//page main heading
.pageHead {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
//form labels
.formLabel {
    margin-right: 10px;
}
// inputs
.outlinedInput {
    border: 1px solid $light-blue;
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 14px;
}
// sort
.sort {
    width: 12px;
    &:first-child {
        transform: rotate(180deg);
        margin-bottom: 2px;
    }
    &.activeSort {
        .sortIcon {
            fill: $theme-color;
        }
    }
}
//main filter head
.startMain {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    color: #49535c;
    justify-content: space-between;
    .filterContainer {
        background: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 6px;
        .filterItem {
            padding: 4px 10px;
            display: flex;
            .mRight {
                margin-right: 5px;
            }
            .text {
                font-weight: 500;
                color: #1d2935;
                font-size: $font-14;
            }
            .dateInput {
                &.from {
                    margin-right: 15px;
                }
                .outlinedInput {
                    .react-datepicker-wrapper {
                        margin-left: 4px;
                        .dateUi {
                            border: unset;
                            width: 85px;
                        }
                    }
                    .react-datepicker-popper {
                        z-index: 2;
                    }
                }
                .arrow {
                    &.leftArrow {
                        transform: scale(-1);
                    }
                    &:hover {
                        .strokeChange {
                            stroke: $theme-color;
                        }
                    }
                }
            }
            &.borderDiv {
                border-left: 1px solid #eaeaea;
            }
        }
    }
    span {
        font-weight: 500;
        color: #1d2935;
    }
    .start {
        margin-right: 30px;
        margin-left: 30px;
    }

    .BtnGO {
        background-color: #6b96f6;
        // padding: 10px 30px;
        color: #fff;
        border: unset;
        margin-left: auto;
        border-radius: 6px;
        background-color: #fff;
        color: #6b96f6;
        &.disabled-btn {
            border: 1px solid #6b96f6;
            background-color: #fff;
            color: #6b96f6;
        }
    }
}
// table
.heightG {
    height: calc(100vh - 240px);
    overflow-y: auto;
    position: relative;
    // border: 1px solid red;
    .tableH {
        width: 100%;
        text-align: center;
        overflow-y: auto;
        border-collapse: separate;
        border-spacing: 0;
        background-color: $white;

        .thead {
            // border: 1px solid #6B96F6;
            border-radius: 10px;
            color: #1d2935;
            font-size: 14px;
            font-weight: $font-6w;
            background: #fff;
            border-collapse: separate;
            position: sticky;
            top: 0;
            z-index: 1;
            tr {
                td {
                    border-color: $light-blue;
                    border-style: solid;
                    background-color: #fff;
                    width: 125px;
                    &:first-child {
                        border-width: 1px 0px 1px 1px;
                        border-top-left-radius: 6px;
                        // border-bottom-left-radius: 6px;
                    }
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8),
                    &:nth-child(9),
                    &:nth-child(10),
                    &:nth-child(11),
                    &:nth-child(12),
                    &:nth-child(13),
                    &:nth-child(14),
                    &:nth-child(15) {
                        border-width: 1px 0px;
                    }
                    &:last-child {
                        border-width: 1px 1px 1px 0px;
                        border-top-right-radius: 6px;
                        // border-bottom-right-radius: 6px;
                    }
                    &:hover {
                        p {
                            color: $theme-color;
                        }
                        .sort {
                            cursor: pointer;
                            .sortIcon {
                                fill: $theme-color;
                            }
                        }
                    }
                }
                th {
                    border-color: $light-blue;
                    border-style: solid;
                    background-color: #fff;
                    // width: 125px;
                    font-weight: $font-6w;
                    &:first-child {
                        border-width: 1px 0px 1px 1px;
                        border-top-left-radius: 6px;
                        // border-bottom-left-radius: 6px;
                    }
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8),
                    &:nth-child(9),
                    &:nth-child(10),
                    &:nth-child(11),
                    &:nth-child(12),
                    &:nth-child(13),
                    &:nth-child(14),
                    &:nth-child(15) {
                        border-width: 1px 0px;
                    }
                    &:last-child {
                        border-width: 1px 1px 1px 0px;
                        border-top-right-radius: 6px;
                        // border-bottom-right-radius: 6px;
                    }
                }
            }
            .MuiTableCell-root {
                text-align: center;
            }
        }
        td {
            padding: 10px;
        }
        .tbody {
            box-shadow: 0 1px 4px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 11%);
            // border-radius: 5px;
            color: #1d2935;

            tr {
                border-radius: 6px;
                td {
                    border-bottom: 1px solid #eaeaea;
                    font-size: 14px;
                    .imgCircle {
                        border: 1px solid blue;
                        border-radius: 50%;
                        height: 85px;
                        width: 85px;
                        margin: 0 auto;
                        overflow: hidden;
                    }
                    // &.taxonomyRefCol
                    // {

                    // }
                }
                .clicked {
                    color: #0057c8;
                    cursor: pointer;
                }
            }
            .MuiTableCell-root {
                text-align: center;
            }
        }
    }
    .loaderContainer {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

// Collapsible table
.inner-table {
    .MuiTypography-root {
        font-weight: 600;
    }
    .MuiTable-root {
        border-collapse: separate;
        .MuiTableHead-root {
            .MuiTableRow-root {
                .MuiTableCell-root {
                    border-color: $theme-color;
                    border-style: solid;
                    font-weight: 600;

                    &:first-child {
                        border-width: 1px 0px 1px 1px;
                        border-top-left-radius: 6px;
                    }
                    &:last-child {
                        border-width: 1px 1px 1px 0px;
                        border-top-right-radius: 6px;
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        border-width: 1px 0px;
                    }
                }
                // .MuiTableBody-root
                // {
                //     .MuiTableRow-root
                //     {
                //         .MuiTableCell-root
                //         {
                //             font-size: 12px !important;
                //         }
                //     }
                // }
            }
        }
    }
    // background: #fcfcfc;
    // .MuiTableHead-root {
    //     font-size: 16px !important;
    // }
    // .css-vtdehq-MuiTableCell-root {
    //     .css-1q1u3t4-MuiTableRow-root {
    //         border-bottom: 1px solid #6B96F6;
    //     }
    //     .MuiTableCell-root {
    //         padding: 5px;
    //         border-radius: 10px;
    //         font-size: 14px;

    //         &:last-child {
    //             border-bottom: unset;
    //         }
    //     }
    // }
}
//pagination
@mixin roundedPagination {
    border: 1px solid $border-color;
    border-radius: 20px;
    height: 36px;
}
.paginationSect {
    margin-top: 30px;
    .MuiPagination-root {
        .MuiPagination-ul {
            @include roundedPagination;
            li {
                button {
                    line-height: 2px;
                }
                .Mui-selected {
                    border-radius: 50%;
                    background-color: $theme-color;
                    color: $white;
                }
            }
        }
    }
    .menuItem {
        @include roundedPagination;
        border-radius: 30px;
        padding: 8px;
        margin-right: 10px;
        margin-left: 20px;
        .role-btn {
            padding: 0;
            .downArrow {
                margin-left: 6px;
            }
        }
    }
}

//dropdown
.dropMenug {
    transform: translate(0px, 40px) !important;
    &.vendorDropdown {
        height: calc(100vh - 448px);
        overflow-y: auto;
    }
    .dropdown-item:hover {
        background-color: $light-blue;
        color: $white;
    }
}

//select field
.optionListContainer {
    .optionContainer {
        margin-top: 5px;
        border: 1px solid $light-blue;
        border-radius: 6px;
        li {
            &:hover {
                background-color: $light-blue;
            }
            &.highlightOption {
                background-color: unset;
                color: #49535c;
                &:hover {
                    background-color: $light-blue;
                    color: $white;
                }
            }
        }
    }
}

.modal-backdrop {
    &.show {
        opacity: 0.25;
    }
}
.modal {
    .modal-header {
        .modalTitle {
            color: $theme-color;
        }
        .btn-close {
            margin: 0;
        }
    }
    .modal-dialog {
        .modal-content {
            background: $white;
            border: 2px solid $theme-color;
            box-shadow: 4px 4px 14px rgb(0 0 0 / 25%);
            border-radius: unset;
            .field-div {
                align-items: center;
                margin-bottom: 25px;
                .inputField::placeholder {
                    color: $placeholder-text-color;
                }
                .fieldTitle {
                    margin-right: 40px;
                }
            }
        }
    }
}

//modal
.formModal {
    .modal-content {
        .modal-title {
            font-weight: 500;
            font-size: 20px;
            color: $theme-color;
        }
        .modal-body {
            .formContainer {
                .formItem {
                    margin-bottom: 15px;
                    label {
                        color: $black;
                        font-size: 14px;
                    }
                    .textfield {
                        border: 1px solid #8b9297;
                        border-radius: 6px;
                        width: 100%;
                        padding: 11px 10px;
                        line-height: 0;
                        &.selectfield {
                            padding: 10px 10px;
                        }
                        .select {
                            border: unset;
                            width: 100%;
                            &:focus-visible {
                                outline: unset;
                            }
                        }
                        &:focus-visible {
                            border: 1px solid $theme-color;
                        }
                    }
                    .selectStatusField {
                        .MuiFormControl-root {
                            .MuiOutlinedInput-root {
                                .MuiSelect-select {
                                    border: 1px solid #8b9297;
                                    border-radius: 6px;
                                    width: 100%;
                                    padding: 0px 10px;
                                    height: 36px;
                                    display: flex;
                                    align-items: center;
                                }
                                .MuiOutlinedInput-notchedOutline {
                                    display: none;
                                }
                                &:hover {
                                    .MuiSelect-select {
                                        border: 1px solid $theme-color;
                                    }
                                }
                            }
                        }
                    }
                }
                input::file-selector-button {
                    border: 1px solid #8b9297;
                    border-radius: 6px;
                    padding: 0px 10px;
                    line-height: 0;
                    background-color: $white;
                    height: 38px;
                    margin-bottom: 15px;
                    margin-right: 20px;
                    cursor: pointer;
                    &:hover {
                        border: 1px solid $theme-color;
                    }
                }
            }
        }
    }
    &.modalHeight {
        .modal-content {
            height: 500px;
            overflow-y: auto;
        }
    }
}

label {
    color: $black;
    font-size: 14px;
}
.textfield {
    border: 1px solid #8b9297;
    border-radius: 6px;
    width: 100%;
    padding: 11px 10px;
    line-height: 0;
    &.selectfield {
        padding: 10px 10px;
    }
    .select {
        border: unset;
        width: 100%;
        &:focus-visible {
            outline: unset;
        }
    }
    &:focus-visible {
        border: 1px solid $theme-color;
    }
}
.formContainer {
    .formItem {
        margin-bottom: 15px;
    }
    input::file-selector-button {
        border: 1px solid #8b9297;
        border-radius: 6px;
        padding: 0px 10px;
        line-height: 0;
        background-color: $white;
        height: 38px;
        margin-bottom: 15px;
        margin-right: 20px;
        cursor: pointer;
        &:hover {
            border: 1px solid $theme-color;
        }
    }
}

// add warehouse and user modal
.addModal {
    .modal-dialog {
        position: absolute;
        top: 120px;
        right: 20px;
        width: 600px;
        margin: 0;
    }
}

//btn focus
.btn-close:focus {
    box-shadow: unset;
}
//error message
.errorMsg {
    font-size: 12px;
    color: red;
}

//back btn
// .backBtnContainer {
//     margin-bottom: 10px;
//     .backBtn {
//         text-decoration: none;
//         font-size: 12px;
//         color: $black;
//         .MuiSvgIcon-root {
//             font-size: 16px;
//             color: $theme-color;
//         }
//     }
// }

//breadcrumbs
.MuiBreadcrumbs-root {
    .MuiBreadcrumbs-ol {
        .MuiBreadcrumbs-li {
            .MuiLink-root {
                font-size: 13px;
                &:hover {
                    color: $theme-color;
                    text-decoration: none;
                }
            }
            .MuiTypography-root-MuiLink-root {
                color: $theme-color;
            }
        }
        .MuiBreadcrumbs-separator {
            color: $theme-color;
        }
    }
}
//scheduler modal

.schedularModal {
    background-color: #000;
    opacity: 0.25;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.winEditpopup {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    left: 50%;
    background: $white;
    border: 2px solid $theme-color;
    box-shadow: 4px 4px 14px rgb(0 0 0 / 25%);
    border-radius: unset;
    transform: translate(-50%, -50%);
    z-index: 1;

    .inputField {
        height: 38px;
        background: #ffffff;
        border: 1px solid #6b96f6;
        border-radius: 10px;
    }

    .outlineBtn {
        width: 100%;
        max-width: 200px;
        background: #f5fbff;
        border-radius: 10px;
        &:hover {
            background: #6b96f6;
            color: #ffffff;
            border: 1px solid #6b96f6;
        }
    }
    .btnGrp {
        margin-top: 100px;
    }
    .react-js-cron {
        margin: 20px 0px;
        .react-js-cron-field {
            margin-bottom: 10px;
            .ant-select {
                background: #ffffff;
                border: 1px solid #6b96f6;
                border-radius: 10px;
                overflow: hidden;
                height: 38px;
                display: flex;
            }
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                border: unset;
                height: 38px;
                align-items: center;
            }
            // .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
        }
    }
}

// close icon
.crossImg {
    height: 20px;
    width: 20px;
}

//dropdown height
.dropHeight {
    height: 150px;
    overflow-y: auto;
}

.mobileNav {
    .menuContainer {
        display: none;
    }
}

// @media screen and (max-width: 767px) {
//     .MuiBox-root {
//         &.contentMain {
//             width: 100%;
//             margin-left: 0px;
//         }
//     }
// }
//tooltip
.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        background-color: $theme-color;
        font-size: 13px;
        .MuiTooltip-arrow {
            color: $theme-color;
        }
    }
}

/*--------new styles--------*/
.responsiveImg {
    width: 100%;
}
.bluelinks {
    font-size: 12px;
    color: $primary-color;
}

.formWrapper {
    position: relative;
    overflow: auto;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-image: url("../assets/images/3d-illustration-interior-modern-warehouse-storage-full-boxes.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .formContainer {
        position: relative;
        width: 100%;
        max-width: 400px;
        border-radius: 4px;

        .formBgImg {
            position: absolute;
            width: 100%;
            max-width: 290px;
        }
        .helptextContainer {
            .helpBox {
                visibility: hidden;
            }
            &:hover {
                .helpBox {
                    visibility: visible;
                }
            }
        }
    }
    .filledBtn {
        background-image: linear-gradient(96.86deg, #0038ba -164.44%, #4f18ff 103.95%);
        color: #fff;
        border-radius: 4px;
        height: 40px;
        display: flex;
        align-items: center;
        text-transform: unset;
        font-size: 14px;
        &:hover {
            box-shadow: none;
            background: #004eff;
        }
    }
}


//responsive
@media (max-width: 768px) {
    .MuiBox-root {
        &.contentMain {
            width: 100%;
            margin-left: 0px;
            height: calc(100vh - 50px);
            overflow-y: auto;
        }
    }
    
}