@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

.startMain 
{
    .processingBtn 
    {
        background: $light-blue;
        color: $white;
        max-width: 134px;
    }
}
.heightG {
    height: calc(100vh - 150px);
    overflow-y: auto;
}
