.user-report {
    .startMain {
        .filterContainer {
            .filterItem {
                &:nth-child(2)
                {
                    padding: 5px 10px;
                    align-items: center;
                }
                .outlinedInput {
                    .role-btn {
                        padding: 0;
                        .margin 
                        {
                            margin-left: 10px;
                        }
                    }
                    .dropdown-toggle::after {
                        margin-left: 20px;
                    }
                    .dropdown-menu {
                        min-width: 200px;
                        border: 1px solid #6b96f6;
                        transform: translate(0px, 40px) !important;
                        border-radius: 6px;
                        font-size: 14px;

                        inset: 0px auto auto 0px !important;
                        margin-top: 10px;
                        & .dropdown-item:hover {
                            background: #f5fbff;
                        }
                    }
                }
            }
        }
    }

}
