@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

.add-warehouse-modal {
    .field-div {
        align-items: center;
        margin-bottom: 25px;
        .inputField::placeholder {
            color: $placeholder-text-color;
        }
        .fieldTitle {
            margin-right: 40px;
        }
        &:last-child {
            margin-bottom: 0px;
        }
    }
}


