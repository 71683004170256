@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

.guidedProdWrapper {
    .startMain {
        flex-direction: row;
        .btnStyleUnset {
            background: unset;
            border: unset;
            padding: 0;
        }
    }
    .formItem {
        .radioBtnContainer {
            display: flex;
            .radioBtn {
                display: flex;
                align-items: center;
                input {
                    margin-right: 10px;
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
        .formBtns {
            margin-top: 50px;
            .FilledBtn {
                margin-right: 20px;
                width: 100%;
                max-width: 153px;
                .svgM {
                    margin-right: 10px;
                }
                &:last-child {
                    margin-right: 0;
                }
                &:hover {
                    background-color: $theme-color;
                    .whiteOnHOver {
                        fill: $white;
                    }
                }
            }
        }
    }
}
