@import "../../../utilities/variables.scss";
@import "../../../utilities/classes.scss";
.formWrapper {
    .formContainer {
        min-height: unset;
        overflow: hidden;
        .formBgImg {
            top: 20px;

            &.setpwdImgStack {
                height: 100%;
                min-height: 316px;
                overflow: hidden;
                padding-top: 20px;
                top: 0;
                border-bottom-left-radius: 4px;
            }
        }
        .keyIcon {
            width: 40px;
            height: 40px;
        }
        .textLink {
            color: #3d4043;
            text-decoration: none;
        }
        .email {
            color: $primary-text-color;
        }
        &.setpwdformContainer {
            overflow: unset !important;
        }
    }
}
