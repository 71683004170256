.btn:focus {
  box-shadow: unset;
}


.btn-primary {
  background-color: #2b468b;
  border-color: #2b468b;
}

.adminDashboard {
  display: flex;

  .warehouseL {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    // h5 {
    //   color: #f96442;
    //   font-weight: 600;
    //   margin: 0;
    // }

    .addD {
      background: #f69f0b;
      border-radius: 5px;
      color: #000;
      font-weight: 500;
      padding: 6px 30px;
      border: unset;
    }
  }

  .warehousedetail {
    padding: 20px;
    margin-bottom: 10px;
    background-color: #fcfcfc;
    .firstN {
      font-size: 18px;
      color: #ff0000;
      font-weight: 700;
    }
  }

  .pagination {
    justify-content: center;
    margin-top: 30px;

    .page-item {
      .page-link {
        border: 1px solid #2b468b;
        color: #2b468b;
      }

      &.active .page-link,
      &:hover .page-link {
        background-color: #2b468b;
        color: #fff;
      }
    }
  }
}

/*my scss*/
.field-list {
  margin-bottom: 10px;

  label {
    font-weight: 500;
  }
}

.add-prefix-sect {
  margin: 20px 0px;

  .inputField {
    margin: 0px 20px 0px 0px;
  }

  .btn-primary {
    padding: 10px 26px;
    background: #f69f0b;
    border: unset;
    border-radius: 5px;
    color: #000;
  }
}

/*prefix modal*/
.field-list-box {
  height: 178px;
  overflow-y: auto;

  .field-list {
    padding: 0px 20px;
  }
}
