@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

body 
{
  
.mainDiv {
  .padding-top-btm {
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
  }
  .btn {
    font-weight: 500;
    font-size: 16px;
    padding: 6px 20px;
    border: 1px solid $light-blue;
    border-radius: 6px;
    color: $light-blue;
    &:hover {
      background: #6b96f6;
      color: $white;
    }
  }
  .loginImg {
    display: flex;
    align-items: center;
    justify-content: start;

    .loginSide {
      width: 100%;
      // object-fit: cover;
      max-width: 500px;
    }
  }
  .loginL {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .logo {
      width: 200px;
    }
  }
  .sign-in-form-sect {
    background: #fff;
    border: 1px solid $light-blue;
    box-sizing: border-box;
    // box-shadow:  1.95px 1.95px 2.6px;
    box-shadow: #B8CEFF 14.4px 14.4px 3.2px;
    // box-shadow: 0px 0px 17px 4px rgba(35, 111, 86, 0.11);
    border-radius: 20px;
    padding: 48px 40px;
    text-align: center;
    .brand {
      margin-bottom: 20px;
    }
    .brand-section {
      h5 {
        font-weight: 700;
        font-size: 28px;
        span {
          color: $light-blue;
        }
      }
      p {
        font-weight: 400;
        font-size: 14px;
        padding: 0px 80px;
        line-height: 23px;
        text-align: center;
      }
    }
  }
  .loginSec {
    margin-top: 30px;
    .incorrect-pwd,
    .incorrect-email {
      font-size: 14px;
      color: red;
      padding-left: 0px;
      margin-bottom: 10px;
    }
    .log-in {
      // margin-top: 4em;
      font-size: 24px;
      font-weight: 700;
      color: #233862;
      margin-bottom: 20px;
    }
    .forgot-pwd-error {
      color: #487baf;
      font-size: 14px;
      margin-bottom: 15px;
    }
    .inputFieldDiv {
      display: flex;
      flex-direction: column;
      justify-items: center;
      // margin-top: 2em;
      .validationB {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
      }
      .show-hide-pwd {
        display: inline-flex;
        align-items: center;
        position: relative;
        margin-bottom: 30px;
        .input-field {
          flex-grow: 2;
          margin-bottom: 0px;
        }
        span {
          position: absolute;
          right: 10px;
          cursor: pointer;
        }
      }
      .input-field {
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #6b96f6;
        // margin-bottom: 15px;
        font-size: 14px;
        color: #1d2935;
      }
      .forgot {
        color: #6b96f6;
        font-size: 14px;
        text-decoration: none;
        text-align: right;
      }
      .btn-div {
        display: flex;
        justify-content: start;
        width: 100%;
        max-width: 278px;
        margin: 60px auto 0px;
        .submitBtn {
          background: #6b96f6;
          border-radius: 6px;
          color: $white;
          font-weight: 400;
          padding: 10px 0px;
          font-size: 16px;
          border: unset;
          &.disabled-btn {
            cursor: not-allowed;
            pointer-events: visible;
          }
          &.same-w-btn {
            width: 100%;
          }
        }
      }
    }
  }
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
  }
}
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .mainDiv {
    .loginImg {
      .loginSide {
        width: 450px;
      }
    }
    .sign-in-form-sect {
      padding: 20px;
      .loginSec {
        .log-in {
          margin-bottom: 1em;
        }
        .inputFieldDiv {
          .validationB {
            margin-bottom: 20px;
          }
          .btn-div {
            margin-top: 60px;
          }
        }
      }
    }
  }
}
