@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";
.kpidetailContainer {
    .kpicardDiv {
        .kpiCard {
            width: 190px;
            height: 100px;
            background: #ffffff;
            border: 2px solid #737373;
            border-radius: 10px;
            padding: 10px;
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:hover {
                border-color: #004eff;
                .subInfo {
                    color: #004eff;
                }
            }

            .kpiInfo {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #373737;
            }

            .subInfo {
                font-size: 20px;
                font-weight: 700;
                color: #737373;
                margin-bottom: 0px;
            }
        }

        .kpifirstCard {
            @extend .kpiCard;
            margin-left: 0px;
        }
    }
}

.kpidetaildailymisContainer {
    .kpicarddailymisDiv {
        .kpifirstdailymisCard {
            width: 180px;
            height: 130px;
            background: #ffffff;
            box-shadow: inset 0px 2px 9px rgba(52, 155, 121, 0.21);
            border: 0.5px solid rgba(54, 185, 142, 0.22);
            border-radius: 10px;
            padding: 10px;
            margin-left: 10px;
            display: flex;
            // flex-direction: column;
            // justify-content: space-between;

            .kpiInfo {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #8b9297;
            }
        }

        .kpifirstCard {
            // @extend .kpiCard;
            margin-left: 0px;
        }
    }
}

.dailyMiscontainer {
    width: 210px;
    background: #ffffff;
    border: 1px solid #737373;
    border-radius: 10px;
    transition: ease-in;
    .bottomDiv {
        border-bottom: none !important;
        // padding: 0px !important;
    }
    .firstdailyMis {
        border-bottom: 1px solid #cacaca;
        align-items: center;
        padding: 5px 10px;
    }
    .sameRow {
        // width: 100%;
        .misHead {
            font-size: 10px;
            margin-bottom: 0px;
        }
        .kpiInfo {
            font-size: 12px;
            font-weight: 500;
        }
        .forcastValue {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
            &.active {
                color: $theme-color;
            }
        }
    }
    &:hover {
        border: 1px solid $theme-color;
    }
}

// .normal {
//     height: 40px;
//     border: 2px solid red;
// }

// .paginatnNav {
//     .MuiPagination-ul {
//         // padding: 4px;
//         // border-radius: 4px;
//         // border-color:#BABDC0;
//         // border-style: solid ;
//         // border-width: 1px 0px 1px 0px;
//         li {
//             .MuiPaginationItem-circular:hover {
//                 background-color: #004eff;
//                 color: #fff;
//             }
//         }

//         .Mui-selected {
//             background-color: #004eff;
//             color: #fff;
//         }

//         .MuiPaginationItem-previousNext {
//             color: #004eff;
//             border: 1px solid #babdc0;
//             border-radius: 6px;
//         }
//     }
// }

// .kpidetailContainer {
//     .startMain {
//         .BtnGO {
//             margin-left: 15px;
//         }
//     }
// }

// .heightG
// {
//  .tableH
//  {
//      .thead
//      {
//          tr
//          {
//              td
//              {
//                 width: 100%;
//              }
//             }
//         }
//     }
// }
.tableH {
    &.vendorDetailsTable {
        position: relative;
    }
    .sortingIconContainer {
        margin-left: 6px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
}

// .react-datepicker-popper
// {
//     z-index: 2 !important;
// }

// .startMain
// {
//     .start
//     {
//         margin-right: 0;
//         margin-left: 0;
//     }
// }
// .mrRight
// {
//     margin-right: 15px;
// }

.vendorDetailContainer {
    .mainHead {
        font-size: 24px;
        font-weight: 500;
    }

    .hHead {
        font-size: 18px;
        font-weight: 500;
        color: #292d32;
        margin-bottom: 2px;
    }

    .subHead {
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .startMain {
        display: block;
        // margin-top: 30px;
        .FilledBtn {
            margin-left: unset;
            // &:first-child {
            //     margin-right: 15px;
            // }
        }
    }
    // .heightG {
    //     .tableH {
    //         .thead {
    //             // z-index: unset;
    //         }
    //     }
    // }
}

.vendorOrderContainer {
    .startMain {
        flex-direction: column;
        align-items: flex-start;
        .serchContainer {
            margin-right: 15px;
        }
        .FilledBtn {
            margin-left: unset;
            &:first-child {
                margin-right: 15px;
            }
        }
    }
}
