@mixin yellow-btn {
    background: #6B96F6;
    border-radius: 5px;
    color: #000;
    color: #fff;
    padding: 8px 35px;
    border: unset;
}

.dashH6 {
    color: #1d2935;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
}

.dashbody {
    .MainWarelist {
        display: flex;
        width: 100%;

        .warehouseContainer {
            width: 100%;
            margin-right: 26px;

            .warhouseUl {
                padding: 0;
                height: calc(100vh - 240px);
                overflow-y: auto;
            }
        }

        .boxList {
            width: 100%;
            max-width: 428px;
            border-left: 1px solid #F5FBFF;
            padding-left: 26px;

            h5 {
                font-size: 18px;
                margin-bottom: 20px;
            }

            .prefixname {
                margin-top: 40px;
                background: #c1eedf;
                border: 1px solid #6B96F6;
                padding: 10px;
                border-radius: 5px;
                max-width: 250px;
                width: 100%;
            }

            .boxMainDetail {
                display: flex;
                align-items: center;
                margin-top: 30px;

                label {
                    color: #1d2935;
                    margin-right: 30px;
                    width: 85px;
                }

                input {
                    border: 1px solid #6B96F6;
                    padding: 10px;
                    border-radius: 8px;
                    width: 100%;
                }
            }

            .startBtn {
                padding: 10px 135px;
                border: unset;
                background-color: #6B96F6;
                color: #fff;
                font-weight: 400;
                margin-top: 50px;
                margin-left: 6rem;
            }

            .warehouseImg {
                margin-top: 80px;
                width: 250px;
            }
        }

        .wareImg {
            width: 100%;
        }

        .warehousedetail {
            background-color: #fcfcfc;
            padding: 20px 20px 0;
            margin-bottom: 10px;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                background: #dcfff4;
                border: 1px solid #6B96F6;
            }

            .firstN {
                font-size: 16px;
                color: #1d2935;
                margin-bottom: 15px;
            }

            .warehouse-heading {
                font-size: 14px;
                color: #1d2935;
            }

            .warehouse-list {
                flex-wrap: wrap;

                .warehouse-list-item {
                    align-items: center;
                    margin-right: 20px;

                    label {
                        font-size: 14px;
                        color: #1d2935;
                    }

                    .enterBtn {
                        // width: 100%;
                        background-color: #fff;
                        border: 1px solid #babdc0;
                        margin-right: 10px;
                        padding: 6px 8px;
                        margin-bottom: 20px;
                        color: #49535c;
                        min-width: 270px;

                        &.active {
                            background-color: #c1eedf;
                            border: 2px solid #6B96F6;
                        }
                    }
                }
            }

            &:focus-within {
                background: #dcfff4;
                border: 1px solid #6B96F6;
            }
        }
    }

    .boxNumber {

        // height: 500px;
        .end-btn {
            @include yellow-btn;
            display: block;
            float: right;
            margin-top: 10px;
        }

        .process {
            margin-bottom: 30px;

            h5 {
                margin: 0;
                font-size: 16px;
            }

            .processInt {
                padding: 10px 20px;
                border: 1px solid #6B96F6;
                border-radius: 8px;
                margin: 0 14px;
                max-width: 497px;
                // width:362px;
                width: 100%;
            }

            .enterBtn {
                border: 1px solid #6B96F6;
                border-radius: 5px;
                color: #6B96F6;
                padding: 10px 45px;
                background-color: #6B96F6;
                margin-left: 15px;
                color: #fff;

                // &:hover {
                //     background-color: #6B96F6;
                //     color: #fff;
                // }
                &.disabled-btn {
                    color: #6B96F6;
                    background-color: #fff;
                }
            }
        }

        .heightG {
            // height: calc(100vh - 150px);
            // overflow-y: auto;

            &.tableProcessHeight {
                height: calc(100vh - 340px);
            }

            .tableProcess {
                width: 100%;
                overflow-x: auto;
                text-align: center;
                background-color: #fff;

                thead.sticky-table-head {
                    // border-radius: 6px;
                    // border: 1px solid #6B96F6;
                    // background-color: #fff;
                    // position: sticky;
                    // top: 0;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    z-index: 2;
                    background-color: #fff;
                }

                .sticky-table-head:after,
                .sticky-table-head:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 100%;
                }

                .sticky-table-head:before {
                    // top: -1px;
                    border-top: 1px solid #6B96F6;
                }

                .sticky-table-head:after {
                    // bottom: -1px;
                    border-bottom: 1px solid #6B96F6;
                }

                td {
                    padding: 10px;
                }

                tbody {
                    box-shadow: 0 1px 4px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 11%);
                    border-radius: 5px;
                    color: #1d2935;
                    font-size: 14px;

                    tr {
                        border-bottom: 1px solid #6B96F6;
                        border-radius: 5px;
                    }
                }

                .headerB {
                    td {
                        font-weight: 500;
                    }
                }

                button {
                    background-color: unset;
                    border: unset;

                    .icon {
                        width: 25px;
                    }
                }

                .norecords {
                    text-align: center;
                    width: 100%;
                    padding: 10px;
                }

                .inputUpdate {
                    border: 1px dashed #6B96F6;
                    padding: 2px 4px;
                    width: 140px;
                }

                .btnUpdate {
                    background-color: #6B96F6;
                    padding: 5px 30px;
                    color: #fff;
                    font-size: 14px;
                    margin-left: 20px;
                }
            }
        }
    }
}

.modalLG {
    :global(modal.show .modal-dialog) {
        :global(.modal-content) {
            background-color: #F5FBFF !important;
        }
    }

    .modalHeader {
        border: unset;
        padding: 2rem 2rem;
        background-color: #F5FBFF;

        .modalTitle {
            font-size: 18px;
            color: #1d2935;
        }
    }

    .modalBody {
        background-color: #F5FBFF;
        padding: 0 2rem;
        border-radius: 0.3rem;

        input {
            border: 1px solid #6B96F6;
            padding: 10px;
            border-radius: 8px;
        }

        .signInBtn {
            @include yellow-btn;
            margin-bottom: 2rem;
            margin-top: 30px;
        }
    }
}

@media screen and (max-width: 1399px) {
    .dashbody .boxNumber .process .processInt {
        max-width: 407px;
    }
}

// .dashbody .MainWarelist .warehousedetail .warehouse-list .warehouse-list-item .enterBtn.active:focus {
//     .warehousedetail {
//         background: red;
//     }
// }