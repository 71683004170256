@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

.formContainer {

    background: white;
    padding: 40px;

    .dateDiv{
        border: 1px solid #8b9297;
        border-radius: 6px;
        padding: 0px 10px;
    }

    .textfielddate {
        border: unset;
        border-radius: 6px;
        width: 100%;
        padding: 11px 10px;
        line-height: 0;
        
    }
    .FilledBtn{
        padding: 0px 50px;
        display: flex;
        align-items: center;
    }
    label{
        font-weight: 500;
    }
    .selectStatusField {
        .MuiFormControl-root {
            .MuiOutlinedInput-root {
                .MuiSelect-select {
                    border: 1px solid #8b9297;
                    border-radius: 6px;
                    width: 100%;
                    padding: 0px 10px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                }
                .MuiOutlinedInput-notchedOutline {
                    display: none;
                }
                &:hover {
                    .MuiSelect-select {
                        border: 1px solid $theme-color;
                    }
                }
            }
        }
    }
}
