@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

.userModal  {
    width: 100%;
    max-width: 500px;
}

.updateProdWrapper {
    .warehouseL {
        margin-bottom: 30px;
        justify-content: space-between;
        .headingD {
            margin-right: 30px;
        }
        .sortContainer {
            border: 1px solid #6b96f6;
            border-radius: 6px;
            display: flex;
            align-items: center;
            height: 40px;
            padding: 8px;
            .sortD {
                margin-right: 6px;
                .downArrow {
                    transform: rotate(180deg);
                    margin-bottom: 4px;
                }
            }
        }
    }
    .startMain {
        flex-direction: column;
        align-items: flex-start;
        .upArrow {
            transform: rotate(180deg);
            margin-bottom: 4px;
        }
    }
    .prodImg {
        width: 100%;
        border: 1px solid #004eff;
    }
    .ProdByL {
        background: white;
        padding: 20px;
        height: calc(100vh - 240px);
        overflow-y: auto;
        .prodStack {
            width: 100%;
            flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
            .ProdItem {
                width: 100%;
                max-width: 150px;
                text-align: center;
                margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
                .FilledBtn {
                    width: 100%;
                    max-width: 150px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .MuiBox-root {
        &.contentMain {
            width: 100%;
            margin-left: 0px;
            padding-bottom: 60px;
        }
    }
    .sidebarNav {
        .MuiDrawer-root {
            display: none;
        }
    }
    .mobileNav {
        .menuContainer {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: $white;
            display: inline-block;
            .menuItem {
                display: flex;
                justify-content: center;
                .MuiList-root {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    width: 100%;
                    max-width: 180px;
                    justify-content: center;
                    .MuiListItem-root {
                        width: 100%;
                        max-width: 60px;
                        .MuiListItemButton-root {
                            justify-content: center;
                            &.Mui-selected {
                                background-color: $theme-color;
                                height: 60px;
                                .MuiListItemIcon-root {
                                    .whiteOnHoverFill {
                                        fill: $white;
                                    }
                                    .whiteOnHoverStroke {
                                        stroke: $white;
                                    }
                                }
                            }
                            .MuiListItemIcon-root {
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .updateProdWrapper {
        .warehouseL {
            align-items: end;
            .headerM {
                flex-direction: column;
            }
            .headingD {
                margin-right: 0px;
            }
        }
        .ProdByL {
            .prodStack {
                flex-direction: column;
                .ProdItem {
                    max-width: 100%;
                    .FilledBtn {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
