.tableBtn {
    background: #ddfaf0;
    border-radius: 6px;
    padding: 5px 50px;
    border: unset;
}

.resolvedBtn {
    border: 1px solid #868686;
    border-radius: 6px;
    padding: 5px 10px;

    span {
        margin-right: 10px;
    }
}

.pointer {
    cursor: pointer;
}

// .paginationSect {
//     margin-top: 30px;
//     font-size: 14px;

//     .menuItem {
//         background-color: rgba(56, 199, 152, 0.0705882353);
//         padding: 6px;
//         border-radius: 4px;

//         .btn {
//             padding: 0px;
//             margin-left: 20px;
//         }
//     }

//     .paginatnNav {
//         .MuiPagination-ul {
//             border: 1px solid #6B96F6;
//             padding: 4px;
//             border-radius: 4px;

//             li {
//                 .MuiPaginationItem-circular:hover {
//                     background-color: #6B96F6;
//                     color: #fff;
//                 }
//             }

//             .Mui-selected {
//                 background-color: #6B96F6;
//                 color: #fff;
//             }
//         }
//     }
// }

.dropMenug {
    border: 1px solid #6b96f6;

    a {
        // background-color: rgba(56, 199, 152, 0.0705882353);
        font-size: 14px;
    }

    a:hover {
        background-color: #fff;
        color: #000;
    }
}

.txtWidth {
    width: 250px;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .nonhoverDiv {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .hoverDiv {
        border: 1px solid #6b96f6;
        position: absolute;
        z-index: 1;
        background: white;
        width: 300px;
        // overflow: auto;
        width: 100%;
        max-width: 600px;
        height: 100px;
        display: none;
        top: 5px;
        overflow: auto;
        word-break: break-word;
        white-space: pre-line;
        padding: 4px;

        p {
            word-break: break-all;
        }
    }

    &:hover {
        .hoverDiv {
            display: block;
        }
    }
}

.editModal {
    background-color: #f5fbff;
    border-radius: 6px;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
}

//edit popup


.winservicesContainer {
    .startMain {
        justify-content: space-between;
    }
   
}

.vendorDetailContainer {
    .heightG {
        .tableH {
            .MuiCheckbox-root {
                padding: 0;
            }
            .thead {
                .MuiTableRow-root {
                    .MuiTableCell-root {
                        padding: 10px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    // .FilledBtn {
    //     max-width: 150px;
    //     margin-top: 20px;
    // }
}
