@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";

.ROValidationWrapper {
    .btnStyleUnset {
        background: unset;
        border: unset;
        padding: 0;
    }
    .FilledBtn {
        &.active {
            background-color: $light-blue;
            color: $white;
        }
        &.flexBtn {
            flex: 1;
        }
    }
    .disabledButton {
        &:hover {
            background-color: white !important;
        }
    }

    .middleRow {
        margin-top: 40px;
        .formContainer {
            margin-bottom: 20px;
        }

        .btnGrp {
            display: flex;
            margin-top: 15px;
            justify-content: right;
            button {
                margin-right: 20px;
                width: max-content;
                margin-left: 0;
                &:hover {
                    background-color: $theme-color;
                    color: $white;
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
        .whiteContainer {
            padding: 30px;
            background-color: $white;
            border-radius: 6px;
            height: calc(100vh - 200px);
            .sucMsgContainer {
                margin-bottom: 25px;
                .message {
                    margin-left: 10px;
                    h6 {
                        font-weight: 400;
                        font-size: 18px;
                        margin-bottom: 12px;
                    }
                    p {
                        color: #737373;
                        font-weight: 400;
                        font-size: 14px;
                        span {
                            color: $black;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    .stepper {
        width: calc(100% - 374px);

        .step {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .stepperLine {
                width: 100%;
                height: 3px;
                background-color: #d3d3d3;
                position: absolute;
                top: 19px;
            }
            .iconCircle {
                width: 40px;
                height: 40px;
                border: 1.5px solid $theme-color;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                background: white;
            }
            p {
                margin-top: 16px;
            }
            &.active {
                .stepperLine {
                    background-color: $theme-color;
                }
                .iconCircle {
                    background-color: $theme-color;
                    .hoverFill {
                        fill: white;
                    }
                }
                p {
                    font-weight: 600;
                }
            }
        }
    }
}

.heightG {
    background-color: #fff;
    border-collapse: initial;
    border-spacing: 0;
    overflow-y: auto;
    text-align: center;
    width: 100%;
    height: unset !important;
    .tableH .thead tr th {
        background-color: #fff;
        border-color: #6b96f6;
        border-style: solid;
        padding: 10px;
        &:first-child {
            border-top-left-radius: 6px;
            border-width: 1px 0 1px 1px;
        }
    }
}

.productContainer {
    background: white;
    padding: 32px;
    margin-top: 20px;
    border: 1px solid #5a5a5a;
    flex-direction: column;
    .imgSection {
        display: flex;
        align-items: center;
        // margin-right: 10px;
        flex-direction: column;
        img {
            border-radius: 2px;
            border: 1px solid #dfdbdb;
            // margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.btnBox {
    .flexBtn {
        flex: 1;
    }
    .firstTwoBtnStack {
        margin-right: 20px;
    }
    .firstTwoBtnStack,
    .lastTwoBtnStack {
        flex-direction: row;
        flex: 1;
    }
}

@media (max-width: 768px) {
    .ROValidationWrapper {
        .stepper {
            width: 100%;
        }
        .middleRow {
            .formContainer,
            .whiteContainer {
                padding: 16px;
            }
            .formContainer {
                .formItem {
                    label {
                        margin-bottom: 8px;
                    }
                }
            }
            .btnGrp {
                justify-content: center;
                button {
                    width: max-content;
                    padding: 0px 16px;
                    justify-content: center;
                    margin-left: 0;
                }
            }
        }
    }
    .productContainer {
        flex-direction: column;
    }
    .btnBox {
        flex-direction: column;
        .firstTwoBtnStack {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}
