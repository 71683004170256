@import "../../utilities/variables.scss";
@import "../../utilities/classes.scss";
// .serchContainer {
//   display: flex;
//   align-items: center;
//   margin-bottom: 30px;
//   color: #49535c;

//   &.search-input {
//     position: relative;

//     img {
//       width: 24px;
//       position: absolute;
//     }
//     .searchImg {
//       left: 9px;
//     }
//     .crossImg {
//       right: 9px;
//     }

//     input {
//       border: 1px solid #6b96f6;
//       padding: 11px 15px 11px 40px;
//       border-radius: 6px;
//       width: 300px;
//       height: 40px;
//       &:focus-visible{
//         outline: unset;
//       }
//     }

//     input,
//     input::-webkit-input-placeholder {
//       font-size: 14px;
//     }

//     .BtnGO {
//       background-color: #6b96f6;
//       padding: 10px 30px;
//       color: #fff;
//       border: unset;
//       margin-left: 30px;
//     }
//   }
// }

// .heightG {
// height: calc(100vh - 150px);
// overflow-y: auto;
// margin: 70px 0px;

// .tableH {
//     width: 100%;
//     text-align: center;
//     background-color: #fff;
//     .thead {
//         border: 1px solid #6B96F6;
//         border-radius: unset;
//         color: #1d2935;
//         font-weight: 500;
//         tr {
//             th {
//                 padding: 10px;
//                 color: #1d2935;
//                 font-weight: 500;
//                 font-size: 16px;
//                 border-bottom: unset;
//             }
//         }
//     }

//     .tbody {
//         box-shadow: 0 1px 4px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 11%);
//         border-radius: 5px;
//         color: #1d2935;
//         font-size: 14px;
//         tr {
//             border-bottom: 1px solid #6B96F6;
//             border-radius: 5px;
//             .btn-width {
//                 width: 160px;
//                 .log-btn {
//                     background: #f4fdfa;
//                     border-radius: 6px;
//                     color: #1d2935;
//                     border: unset;
//                     padding: 0px;
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         .inner-table
//         {
//             background: #FCFCFC;
//         }
//     }
// }
// .css-1ndpvdd-MuiTableCell-root
// {
//     padding: 10px;

//        border-radius: 10px;
//         color: #1d2935;
//        font-weight: 500;
//        border-bottom: unset;
// }
// }

// .MuiPaper-root.scanned-table-container {
//   &.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
//     border-radius: unset;
//   }

//   .MuiTableHead-root {
//     border: 1px solid #6b96f6;
//     border-radius: 6px;

//     .MuiTableCell-root {
//       // border-bottom: unset;
//       padding: 10px;
//       font-size: 16px;
//     }
//   }

//   .MuiTableBody-root {
//     .css-2blr9s-MuiTableRow-root {
//       .MuiTableCell-root {
//         // border-bottom: unset;
//         padding: 5px;

//         border-radius: 6px 6px 0px 0px;
//       }
//     }

// .inner-table {
//   background: #fcfcfc;

//   .MuiTableHead-root {
//     font-size: 16px !important;
//   }

//   .css-vtdehq-MuiTableCell-root {
//     .css-1q1u3t4-MuiTableRow-root {
//       border-bottom: 1px solid #6b96f6;
//     }

//     .MuiTableCell-root {
//       padding: 5px;
//       border-radius: 10px;
//       font-size: 14px;

//       &:last-child {
//         border-bottom: unset;
//       }
//     }
//   }
// }
//   }
// }

// .css-apqrd9-MuiTableBody-root {
//   .inner-table.css-1q1u3t4-MuiTableRow-root {
//     .MuiBox-root.css-1ynyhby {
//       background-color: #fcfcfc;

//       .MuiTypography-h6 {
//         font-size: 16px;
//       }

//       table {
//         border: 1px solid #6b96f6;

//         thead {
//           th {
//             padding: 5px 16px;
//             font-size: 14px;
//           }
//         }

//         tbody {
//           th {
//             font-size: 12px;
//           }
//         }
//       }
//     }
//   }
// }

// .css-1q1u3t4-MuiTableRow-root {
//   .css-1ex1afd-MuiTableCell-root {
//     border-bottom: 1px solid #6b96f6;
//   }
// }

// .css-apqrd9-MuiTableBody-root {
//   .css-vtdehq-MuiTableCell-root:last-child {
//     border-bottom: 1px solid #6b96f6;
//   }
// }

// .nav-link {
//   color: gray;
// }

// .nav-link:hover {
//   color: black;
// }
// .btnFilter {
//   border: none;
//   .outlinegreenBtn {
//     // width: 100%;
//     // max-width: 100px;
//     padding: 0px 15px;
//     background: #ffffff;
//     border: 1px solid #6b96f6;
//     border-radius: 10px;
//     font-size: 14px;
//     margin-left: auto;
//   }
// }
// .outlineBtn {
//   width: 100%;
//   max-width: 150px;
//   height: 50px;
//   background: #f5fbff;
//   border-radius: 10px;
//   &:hover {
//     background: #6b96f6;
//     color: #ffffff;
//     border: 1px solid #6b96f6;
//   }
// }
// .btnGrp {
//   margin-top: 100px;
// }
// .text {
//   font-size: 18px;
//   color: #8b9297;
// }
//mis daily report
// .misdailyFilter {
//   .start {
//     margin-left: 10px;
//     margin-right: 0px;
//   }
//   .end {
//     margin-left: 10px;
//     margin-right: 0px;
//   }
//   .dateUi {
//     margin-left: 10px;
//   }
// }

//new css
// .filterContainer {
//   .borderDiv {
//     border-right: 1px solid #eaeaea;
//     border-left: 1px solid #eaeaea;
//     .mRight {
//       margin-right: 15px;
//     }
//     &:last-child {
//       border-right: unset;
//     }
//   }
//   .btnFilter {
//     .dropdown {
//       background-color: white;
//     }
//     .outlinegreenBtn {
//       border: 1px solid #6b96f6;
//       padding: 10px 15px;
//       border-radius: 10px;
//       margin-left: 15px;
//       font-size: 14px;
//     }
//   }
//   .multiSelectContainer {
//     .searchWrapper {
//       border: unset;
//       padding: 0;
//       display: flex;
//       flex-direction: row-reverse;
//       .chip {
//         margin-bottom: 0;
//         background-color: #6b96f6;
//       }
//     }
//     input {
//       border: 1px solid #6b96f6;
//       padding: 10px 15px;
//       border-radius: 10px;
//       margin-left: 15px;
//       font-size: 14px;
//       margin: 0;
//       margin-right: 10px;
//     }
//     li {
//       text-align: left;
//       &.highlightOption,
//       &:hover {
//         background-color: #6b96f6;
//       }
//     }
//   }

// }
// .applyFilterBtn {
//   background-color: white;
//   padding: 10px 30px;
//   color: #6b96f6;
//   margin-left: 15px;
//   border-radius: 5px;
//   border: 1px solid #6b96f6;
//   margin-left: 15px;
// }

.barChart {
  .recharts-wrapper {
    width: 100% !important;
    .recharts-surface {
      width: 100% !important;
    }
  }
}

.misReportContainer {
  .startMain {
    justify-content: space-between;
    .outlinedInput {
      .margin 
      {
        margin-right: 4px;
      }
      .btn {
        padding: 0;
      }
    }
    .searchWrapper {
      border: 1px solid $light-blue;
      padding: 6px 10px;
      border-radius: 6px;
      font-size: 14px;
      .chip {
        padding: 2px 10px;
        margin-bottom: 0px;
      }
    }
    span {
      color: $white;
    }
    .FilledBtn {
      margin-left: unset;
    }
    .filterContainer {
      .filterItem {
        align-items: center;
        .margin 
        {
          margin-right: 10px;
        }
      }
    }
  }
}

.chardLoader 
{
  top: 100%;
}