.dropdown-toggle::after{
    display: none !important;
}
/* .react-js-cron-field , .react-js-cron-hours{
    display: flex !important;
} */
.react-js-cron{
    display: flex !important;
}
.ant-btn-dangerous.ant-btn-primary{
    display: none;
}