
* {
  margin: 0;
  padding: 0;
  font-family: "sfuidisplay";
}
.MuiTypography-root 
{
  font-family: "sfuidisplay" !important;
}
/* body 
{
  background-color: rgba(245, 251, 255, 1) !important;
} */
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: unset;
  font-size: 14px;
  font-family: "sfuidisplay";
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
}
.spinner-border {
  left: 50%;
  top: 50%;
  z-index: 999;
  margin: 0 auto;
  position: absolute;
}
.disabled-btn {
  cursor: not-allowed;
  /* background-color: #fff;
  border: 1px solid #38c798; */
}

/*---------Custom scrollbar starts----------*/

@media (min-width: 992px) {
  /* width */

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #dee2e6;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #979292bf;
    border-radius: 0;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0);
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #979292;
  }
}
/*---------Custom scrollbar Ends----------*/

:global(.modal-backdrop.show) {
  opacity: 0.6;
}
:global(.modal-backdrop) {
  background-color: #fff;
}

input {
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
