.error-img {
    margin-bottom: 40px;
    img {
        max-width: 700px;
        object-fit: cover;
        width: 100%;
    }
}
.error-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    label {
        font-weight: 600;
        font-size: 28px;
        color: #1d2935;
        margin-bottom: 15px;
    }
    .back-btn {
        background: #6B96F6;
        border-radius: 6px;
        color: #fff;
        padding: 8px 16px;
        border: 1px solid #6B96F6;
    }
}
