@import "../utilities/variables.scss";

//fontstyle
.fontStyle {
    font-size: 14px;
    font-weight: 300;
}
//filledBtn

// main content container
.contentMain {
    margin-top: 50px;
    width: calc(100% - 60px);
    margin-left: 60px;
    height: calc(100vh - 50px);
    overflow-y: auto;
}
//searchinput
.searchInput {
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    height: 32px;
    background: $white;
    padding: 0px 10px;
    align-items: center;
    width: 156px;
    display: flex;
    .searchIcon {
        margin-right: 8px;
    }
    input {
        border: unset;
        @extend .fontStyle;
    }
}
//table
.heightG {
    height: calc(100vh - 240px);
    overflow-y: auto;
    position: relative;
    .tableH {
        width: 100%;
        text-align: left;
        overflow-y: auto;
        border-collapse: separate;
        border-spacing: 0;
        background-color: $white;
        .thead {
            color: #1d2935;
            font-size: 14px;
            font-weight: $font-6w;
            background: #fff;
            border-collapse: separate;
            position: sticky;
            top: 0;
            z-index: 1;
            height: 40px;
            tr {
                td {
                    border-bottom: 1px solid #e0e0e0;
                    &:last-child {
                        width: 170px;
                    }
                }
            }
        }
        .tbody {
            tr {
                height: 40px;
                td {
                    border-bottom: 1px solid #e0e0e0;
                    font-size: 14px;
                    font-weight: 300;
                    .tBtn {
                        @extend td;
                        border: unset;
                        background: unset;
                        margin-left: 40px;
                        svg {
                            margin-right: 4px;
                        }
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

//table modal
.tableModal {
    .modal-content {
        border: unset;
        border-radius: 4px;
    }
}
//datepicker
.datepickerWrapper {
    .calendarIcon {
        width: 100%;
        // margin-right: 10px;
    }
    .dateUi {
        border: unset;
        width: 85px !important;
        @extend .fontStyle;
        text-align: center;
    }
    .react-datepicker-wrapper {
        margin-left: 4px;
    }
    .react-datepicker-popper {
        z-index: 2;
    }
}

//asterisk
.required:after {
    content: " *";
    color: #1a1918;
}
