.connectionContainer {
    .startMain {
        justify-content: space-between;
    }

    .FilledBtn {
        margin-left: unset;
    }
}

// .addModal {
//     .modal-content {
//         background-color: #f5fbff;
//         border-radius: 6px;
//         border: 1px solid #f3f3f3;
//         box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

//         .modal-title {
//             font-weight: 500;
//             font-size: 16px;
//             color: #282828;
//         }

//         label {
//             font-weight: 300;
//             font-size: 14px;
//             color: #2a2a2a;
//             margin-bottom: 10px;
//         }

//         .textfield {
//             padding: 6px 10px;
//             border: 0;
//             border-radius: 2px;

//             &.selectfield {
//                 padding: 8px 10px;
//             }
//         }
//     }

//     .sbtBtn {
//         background: #6b96f6;
//         box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
//         border-radius: 4px;
//         color: white;
//         border: 0;
//         padding: 8px 15px;
//         font-weight: 400;
//         font-size: 14px;
//         float: right;
//         margin-top: 1rem;
//     }
// }
