.vendorContainer {
    .startMain {
        .FilledBtn {
            margin-left: unset;
           
        }
    }
    .heightG {
        .tableH {
            .menuItem {
                .dropMenug {
                    .clickItem {
                        &:hover {
                            background-color: #f5fbff !important;
                        }
                    }
                }
            }
        }
    }
}
