@import "../../../utilities/classes.new.scss";
@import "../../../utilities/variables.scss";

.numberWithBg {
    padding: 0px 4.3px;
    background: #e0e0e0;
    font-size: 12px;
    line-height: 16px;
    border-radius: 2px;
}
.visible {
    border: 1px solid red;
}
