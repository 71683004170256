.startMain {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    color: #49535c;
    // justify-content: flex-start;
    // .serchContainer
    // {
    //     margin-right: 15px;
    // }
    .borderDiv
    {
        align-items: center;
    }
    // &.search-input {
    //     position: relative;
    //     img {
    //         width: 24px;
    //         position: absolute;
    //         left: 9px;
    //     }
    //     input {
    //         border: 1px solid #6B96F6;
    //         padding: 11px 15px 11px 40px;
    //         border-radius: 6px;
    //         width: 300px;
    //     }
    //     input,
    //     input::-webkit-input-placeholder {
    //         font-size: 14px;
    //     }
    //     .BtnGO {
    //         background-color: #6B96F6;
    //         padding: 10px 30px;
    //         color: #fff;
    //         border: unset;
    //         margin-left: auto;
            
    //     }
    // }
}
// .heightG {
    // height: unset;
    // overflow-y: auto;

    // .tableH {
    //     width: 100%;
    //     text-align: center;
    //     background-color: #fff;
    //     .thead {
    //         border: 1px solid #6B96F6;
    //         border-radius: unset;
    //         color: #1d2935;
    //         font-weight: 500;
    //         tr {
    //             th {
    //                 padding: 10px;
    //                 color: #1d2935;
    //                 font-weight: 500;
    //                 font-size: 16px;
    //                 border-bottom: unset;
    //             }
    //         }
    //     }

    //     .tbody {
    //         box-shadow: 0 1px 4px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 11%);
    //         border-radius: 5px;
    //         color: #1d2935;
    //         font-size: 14px;
    //         tr {
    //             border-bottom: 1px solid #6B96F6;
    //             border-radius: 5px;
    //             .btn-width {
    //                 width: 160px;
    //                 .log-btn {
    //                     background: #f4fdfa;
    //                     border-radius: 6px;
    //                     color: #1d2935;
    //                     border: unset;
    //                     padding: 0px;
    //                     width: 100%;
    //                     height: 100%;
    //                 }
    //             }
    //         }
    //         .inner-table
    //         {
    //             background: #FCFCFC;
    //         }
    //     }
    // }
    // .css-1ndpvdd-MuiTableCell-root
    // {
    //     padding: 10px;

    //        border-radius: 10px;
    //         color: #1d2935;
    //        font-weight: 500;
    //        border-bottom: unset;
    // }
// }
// .paginationSect {
//     margin-top: 30px;
//     font-size: 14px;
//     .menuItem
//     {
//         background-color: rgba(56, 199, 152, 0.0705882353);
//         padding: 6px;
//         border-radius: 4px;
//             .btn
//             {
//                 padding: 0px;
//                 margin-left: 20px;
//             }
//     }
//     .paginatnNav {
//         .MuiPagination-ul {
//             border: 1px solid #6B96F6;
//             padding: 4px;
//             border-radius: 4px;
//             li {
//                 .MuiPaginationItem-circular:hover {
//                     background-color: #6B96F6;
//                     color: #fff;
//                 }
//             }
//             .Mui-selected {
//                 background-color: #6B96F6;
//                 color: #fff;
//             }
//         }
//     }
//     .dropMenug {
       
//         border: 1px solid #6B96F6;
//         a {
//             background-color: rgba(56, 199, 152, 0.0705882353);
//             font-size: 14px;
//         }
//         a:hover {
//             background-color: #6B96F6;
//             color: #fff;
//         }
//     }
// }

.MuiPaper-root.scanned-table-container {
    &.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
        border-radius: unset;
    }
    .MuiTableHead-root {
        border: 1px solid #6B96F6;
        border-radius: 6px;
        .MuiTableCell-root {
            // border-bottom: unset;
            padding: 10px;
            font-size: 16px;
        }
    }
    // .MuiTableBody-root {
    //     .css-2blr9s-MuiTableRow-root {
    //         .MuiTableCell-root {
    //             // border-bottom: unset;
    //             padding: 5px;
    //             border-radius: 6px 6px 0px 0px;
    //         }
    //     }
        
    // }
}

// .css-apqrd9-MuiTableBody-root {
//     .inner-table.css-1q1u3t4-MuiTableRow-root {
//         .MuiBox-root.css-1ynyhby {
//             .MuiTypography-h6 {
//                 font-size: 16px;
//             }
//             thead {
//                 th {
//                     padding: 5px 16px;
//                     font-size: 14px;
//                 }
//             }
//             tbody {
//                 th {
//                     font-size: 12px;
//                 }
//             }
//         }
//     }
// }

.css-1q1u3t4-MuiTableRow-root {
    .css-1ex1afd-MuiTableCell-root {
        border-bottom: 1px solid #6B96F6;
    }
}

// .css-apqrd9-MuiTableBody-root {
//     .css-vtdehq-MuiTableCell-root {
//         border-bottom: 1px solid #6B96F6;
//     }
// }


//new css
.startMain
{
    .FilledBtn
    {
        margin-left: 0px;
    }
    
}