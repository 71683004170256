@import "../../utilities/variables.scss";

.dailyMisReportContainer {
    .startMain {
        justify-content: space-between;
        .outlinedInput {
            .margin {
                margin-right: 4px;
            }
            .btn {
                padding: 0;
            }
        }
        .searchWrapper {
            border: 1px solid $light-blue;
            padding: 6px 10px;
            border-radius: 6px;
            font-size: 14px;
            .chip {
                padding: 2px 10px;
                margin-bottom: 0px;
            }
        }
        span {
            color: $white;
        }
        .FilledBtn {
            margin-left: unset;
        }
        .filterContainer {
            .filterItem {
                align-items: center;
                .margin {
                    margin-right: 10px;
                }
            }
        }
        
    }
}
